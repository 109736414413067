.list {
    & ul {
        @media (min-width: theme('screens.md')) {
            @apply grid-cols-3;

            display: grid;
            gap: theme('spacing.075');
        }
    }
}

.disabled {
    & ul {
        @media (max-width: 767px) {
            @apply grid-cols-2;

            display: grid;
            gap: theme('spacing.075');
        }
    }

    & .option {
        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

.option {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    width: 10rem;
    height: 100%;
    padding: theme('spacing.100');
    border-radius: theme('card.borderRadius.default');
    background-color: theme('fields.default.background');
    border: 1px solid theme('colors.decorators.subtle');
    transition: border-color theme('transitionDuration.fast') theme('transitionTimingFunction.default');
    cursor: pointer;

    @media (min-width: theme('screens.md')) {
        width: 100%;
    }

    & img {
        width: 0.75rem;
        height: 0.75rem;
    }

    &:focus-visible {
        outline: none;
    }

    &:hover,
    &:focus,
    &:focus-within {
        border-color: theme('colors.actions.secondary');
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        border: 2px solid theme('colors.actions.secondary');
        transition: opacity theme('transitionDuration.fast') theme('transitionTimingFunction.default');
        box-shadow: 0px 0px 0px 2px rgba(23, 167, 196, 0.10);
        opacity: 0;
        pointer-events: none;
    }

    &[aria-checked=true] {
        border-color: transparent;

        &:before {
            opacity: 1;
        }
    }
}
