.card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    transition: box-shadow 350ms ease-out;

    &:hover {
        box-shadow: theme('treatmentCards.default.hover.shadow');
    }
}

.button {
    @apply mt-125 py-150 px-100 relative;
}

.cutoutBackground {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    transform: translate(0, 50%);
}

.buttonLink {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: theme('button.spacing');
    width: 100%;
}
