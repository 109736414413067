.wrapper {
    position: relative;
    margin-top: theme('fields.spacing.default');
    border: 1px solid theme('colors.decorators.subtle');
    border-radius: theme('borderRadius.100');
    overflow: visible;

    & > button {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: theme('fields.padding.default');
        text-align: left;
        outline-offset: theme('spacing.025');

        @media (min-width: theme('screens.md')) {
            padding-left: theme('spacing.150');
            border-left: 1px solid theme('colors.neutrals.transparent');
        }

        & > span:last-of-type {
            margin-left: auto;
            padding-left: theme('spacing.100');
            padding-right: theme('spacing.025');
        }

        & svg {
            transform: rotate(90deg);
            fill: theme('colors.actions.secondary');
        }
    }
}

.dropDown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: theme('spacing.025') theme('fields.padding.default') theme('fields.padding.default');
    transition-duration: theme('transitionDuration.fast'); /* Not sure if this is needed for Transition component */
    z-index: 1000;

    &:before {
        content: '';
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 100dvh;
        background-color: theme('colors.content.foundation');
        opacity: 0.75;
        transition-property: opacity;
        transition-duration: theme('transitionDuration.fast');
        transition-timing-function: linear;

        @media (min-width: theme('screens.md')) {
            position: absolute;
            top: calc(0px - calc(theme('fields.padding.default') - 1px));
            bottom: auto;
            left: -1px;
            width: calc(100% + 2px);
            max-height: 35.5rem;
            height: calc(100% + theme('fields.padding.default'));
            border-width: 0 1px 1px;
            border-style: solid;
            border-color: theme('colors.decorators.subtle');
            border-radius: 0 0 theme('borderRadius.100') theme('borderRadius.100');
            background-color: theme('fields.default.background');
            opacity: 1 !important;
            transition-property: max-height;
            pointer-events: none;
        }
    }
}

.dropDownTransition:before {
    opacity: 0;

    @media (min-width: theme('screens.md')) {
        max-height: 0.75rem;
    }
}

.overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 21.25rem;
    padding: theme('spacing.150') theme('spacing.100') theme('spacing.100');
    background-color: theme('fields.default.background');
    border-radius: theme('borderRadius.200') theme('borderRadius.200') 0 0;
    transition-property: transform;
    transition-duration: theme('transitionDuration.fast');
    transition-timing-function: linear;

    @media (min-width: theme('screens.md')) {
        position: relative;
        height: auto;
        max-height: 17.75rem;
        padding: 0;
        transition-property: opacity;
        transform: none;
    }

    & ul {
        @apply space-y-050;

        position: relative;
        flex-grow: 1;
        max-height: 17.75rem;
        overflow-y: auto;

        &:focus-visible,
        &:focus, {
            outline: none;
        }

        & li {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: theme('spacing.100');
            padding: theme('spacing.050') theme('spacing.100') theme('spacing.050') theme('spacing.075');
            border: 1px solid theme('colors.decorators.subtle');
            border-radius: theme('borderRadius.100');

            &[aria-disabled=true] {
                cursor: not-allowed;
            }

            &:not([aria-disabled=true]) {
                &:before {
                    content: '';
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    width: calc(100% + 2px);
                    height: calc(100% + 2px);
                    border-radius: inherit;
                    border: 2px solid theme('colors.actions.secondary');
                    transition: opacity theme('transitionDuration.fast') theme('transitionTimingFunction.default');
                    opacity: 0;
                    pointer-events: none;
                }

                &:hover:before,
                &[data-headlessui-state=active]:before {
                    opacity: 1;
                }
            }
        }
    }
}
