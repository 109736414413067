/* Position left column, below reasons to buy. */
.grid {
    @apply grid-cols-1;

    display: grid;
    gap: theme('spacing.rhythm-xsmall');

    @media (min-width: theme('screens.md')) {
        grid-template-columns: 280px 1fr;
        grid-template-rows: repeat(3, auto);
        column-gap: theme('spacing.200');
        row-gap: 0;

        /* If there is no reasonsToBuy section, we need to centre the header. */
        &:not(:has(.reasonsToBuy)) .header {
            grid-area: 1 / 2 / 3 / 3;
            align-self: center;
        }
    }

    @media (min-width: theme('screens.lg')) {
        grid-template-rows: repeat(4, auto);

        /* If there is no reasonsToBuy section, we need to set header back to the top. */
        &:not(:has(.reasonsToBuy)) .header {
            grid-area: 1 / 2 / 1 / 3;
            align-self: start;
        }
    }
}

/* Position left column, below header. */
.image {
    padding: theme('spacing.050');
    border-radius: theme('borderRadius.100');
    border: 1px solid theme('colors.decorators.muted');

    /* Position top left */
    @media (min-width: theme('screens.md')) {
        grid-area: 1 / 1 / 3 / 2;
        padding: 0;
        border: none;
    }

    & img {
        max-width: 10rem;
        width: 100%;
        margin: 0 auto;

        @media (min-width: theme('screens.md')) {
            max-width: 17.5rem;
        }
    }
}

.header {
    /* Position top right */
    @media (min-width: theme('screens.md')) {
        grid-area: 1 / 2 / 1 / 3;
        align-self: end;
    }

    /* Position top right */
    @media (min-width: theme('screens.lg')) {
        grid-area: 1 / 2 / 1 / 3;
        align-self: start;
    }
}

.reasonsToBuy {
    /* Position top right, under header */
    @media (min-width: theme('screens.md')) {
        grid-area: 2 / 2 / 2 / 3;
        padding-top: theme('spacing.100');
    }

    /* Position bottom left, under image */
    @media (min-width: theme('screens.lg')) {
        grid-area: 3 / 1 / 4 / 2;
        padding-top: theme('spacing.150');
    }
}

.treatmentSelector {
    /* Position bottom, across all columns */
    @media (min-width: theme('screens.md')) {
        grid-area: 3 / 1 / 3 / 3;
        padding-top: theme('spacing.200');
    }

    /* Position bottom right, under header */
    @media (min-width: theme('screens.lg')) {
        grid-area: 2 / 2 / 5 / 3;
        padding-top: theme('spacing.rhythm-xsmall');
    }
}
