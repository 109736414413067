.track {
    @media (max-width: 767px) {
        display: flex;
        margin-left: calc(var(--gutter, 1rem) * -1);
        margin-right: calc(var(--gutter, 1rem) * -1);
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        & > li {
            scroll-snap-align: start;
            padding-left: var(--gutter, 1rem);

            &:last-child {
                padding-right: var(--gutter, 1rem);
            }
        }
    }
}

.scrollPadding {
    @media (max-width: 767px) {
        padding-inline-end: calc(100% - calc(var(--card-width) - calc(var(--gutter, 1rem) * 2)));
    }
}

.indicators {
    display: flex;
    margin-top: theme('spacing.075');
    justify-content: center;
    gap: theme('spacing.050');

    @media (min-width: theme('screens.md')) {
        display: none;
    }

    & button {
        width: theme('spacing.025');
        height: theme('spacing.025');
        border-radius: theme('spacing.025');
        background: theme('colors.decorators.subtle');
        cursor: pointer;
        transition: 100ms width linear;
    }
}

@supports (animation-timeline: scroll()) {
    .indicators {
        & button {
            animation: blink both linear;
            animation-range:
                contain calc(100% - calc(var(--card-width) - var(--gutter, 1rem)))
                contain calc(100% + calc(var(--card-width) - var(--gutter, 1rem)));
            transition: none;
        }
    }

    @keyframes blink {
        50% {
            width: theme('sizes.400');
        }
    }
}
