.AccountSettingsWeightHeight_bmiSection__uM4Ld {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-top-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(202 216 226 / var(--tw-border-opacity));
    padding-top: 16px;
    padding-bottom: 16px
}
.DeleteCardOverlay_overlay__PBYe5 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
    background-color: #FFF;
    opacity: 0;
    transform: translateY(-0.5rem);
    pointer-events: none;
    transition-property: opacity, transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.15, 0.66, 0.46, 0.98);
    padding: 12px;
    border-radius: 12px;
}

    .DeleteCardOverlay_overlay__PBYe5 button {
    font-family: fields, arial, system-ui, serif;
    font-weight: 500;
    font-size: 11.237px;
    line-height: 150%;
}

    .DeleteCardOverlay_overlay__PBYe5 button:hover,
        .DeleteCardOverlay_overlay__PBYe5 button:focus {
            text-decoration: underline;
        }

.DeleteCardOverlay_overlayShow__j3jgQ {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.DeleteCardOverlay_heightAnimation__akNof {
    transition-duration: 300ms;
    transition-property: height, margin-top, opacity;
    transition-timing-function: cubic-bezier(0.6, 0.1, 0.87, 0.4);
}

.DeleteCardOverlay_animateOpacity__td0gP {
    opacity: 0;
    transition: opacity 300ms cubic-bezier(0.15, 0.66, 0.46, 0.98);
    overflow: hidden;
    pointer-events: none;
}

.DeleteCardOverlay_icon__Wb8Le {
    flex-shrink: 0;
    border-radius: 0.25rem;
    border: 1px solid #CAD8E2;
}

.DeleteCardOverlay_icon__Wb8Le img {
        padding: 12px;
        box-sizing: content-box;
    }

.Loading_loadingWrap__Td9Se {
    padding-top: 80px;
    padding-bottom: 80px;
}

.Loading_loading__WJJX0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Loading_loading__WJJX0.Loading_loadingInline__RUUny {
        display: inline-block;
    }

.Loading_loadingFullScreen__84kv0 {
    position: absolute;
    inset: 0;
}

.AccountOpenOrdersList_container__zylGz {

    display: flex;

    width: 100%;

    flex-direction: column;

    row-gap: 24px
}

.AccountOpenOrdersList_list__G8WPp {

    display: flex;

    flex-direction: column;

    row-gap: 16px
}
button.QuantityButton_button__M0x1l {

    font-size: 11.237px;

    line-height: 150%;

    display: flex;
    align-items: center;
    gap: 4px;
    max-width: 8.75rem;
    padding: 8px;
    border: 1px solid #CAD8E2;
    border-radius: 6px;
    transition: border-color 600ms cubic-bezier(0.23, 1, 0.32, 1);
}

    button.QuantityButton_button__M0x1l:hover,
    button.QuantityButton_button__M0x1l:focus {
        border-color: #007ACC;
    }
.BasketItem_item__u0qBx {
    position: relative;
    padding: 12px 0;
    transition-property: height, opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.15, 0.66, 0.46, 0.98);
}

.BasketItem_image__E3IiL {
    -o-object-fit: contain;
       object-fit: contain;
    flex-shrink: 0;
    width: 32px;
}

.BasketItem_unavailableImage__Wjw24 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    border: 1px solid #CAD8E2;
}

.BasketItem_unavailableImage__Wjw24 svg {
        width: 50%;
        height: 50%;
    }

.SelectPersonalTitle_select__4xMvd {
        width: 180px !important;
}

    .SelectPersonalTitle_select__4xMvd + img {
        position: absolute;
        top: 50%;
        left: 155px;
        margin: 0;
        transform: translateY(-50%);
        pointer-events: none;
    }

.TextInput_date__dO13p::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: url('/images/icons/calendar-light.svg') no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        border-width: thin;
    }

.DateOfBirth_fieldSpacing____0N5 > * + * {
        margin-top: 4px;
    }

.DateOfBirth_field__CQUJz {
    width: 4.5rem;
}

.DateOfBirth_dob_question__JoHU_ {
    display: flex;
    gap: 4px;

}

.DateOfBirth_dob_question__JoHU_ input {
        -moz-appearance: textfield;
    }

.DateOfBirth_dob_question__JoHU_ input::-webkit-outer-spin-button,
        .DateOfBirth_dob_question__JoHU_ input::-webkit-inner-spin-button {
        -webkit-appearance: none;
                appearance: none;
            margin-right: 0;
}

.DateOfBirth_age__wYpX0 {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.75rem;
    padding: 0 12px;
    margin-left: auto;
    background: #007ACC;
    border-radius: 6px;
    color: #FFFFFF;
}

.JoiFormInputWrap_fieldValid__m4lem input, .JoiFormInputWrap_fieldValid__m4lem select {

        background: #FFFFFF;
        border: 1px solid #72EDC7;
        opacity: 1;
        fill: #303C69;
    }

.JoiFormInputWrap_fieldInvalid__9fWYh input, .JoiFormInputWrap_fieldInvalid__9fWYh select {

        background: #FFFFFF;
        border: 1px solid #DB0F64;
        opacity: 1;
        fill: #303C69;
    }

.AccountDashboard_container__IM5Q5 {
padding-left: 16px;
padding-right: 16px;
width: 100%
}

.AccountDashboard_container__IM5Q5 > :not([hidden]) ~ :not([hidden]) {
--tw-space-y-reverse: 0;
margin-top: calc(48px * calc(1 - var(--tw-space-y-reverse)));
margin-bottom: calc(48px * var(--tw-space-y-reverse))
}

.AccountDashboard_container__IM5Q5 {
padding-top: 24px;
padding-bottom: 24px
}

    @container (min-width: 768px) {.AccountDashboard_container__IM5Q5 {
padding-left: 24px;
padding-right: 24px
}
    }

.AccountDashboard_hr__Ou7TW.AccountDashboard_mobile__Vvisr {
display: block
}

@container (min-width: 768px) {

.AccountDashboard_hr__Ou7TW.AccountDashboard_mobile__Vvisr {
            display: none
    }
        }

.AccountDashboard_reordersAndSavedBaskets__IMDQg {
display: grid;
grid-template-columns: repeat(1, minmax(0, 1fr));
gap: 48px
}

@container (min-width: 768px) {

.AccountDashboard_reordersAndSavedBaskets__IMDQg {
grid-template-columns: repeat(2, minmax(0, 1fr))
}
    }

@container (min-width: 1152px) {

.AccountDashboard_reordersAndSavedBaskets__IMDQg {
grid-template-columns: repeat(2, minmax(0, 1fr));
-moz-column-gap: 24px;
     column-gap: 24px
}
    }

@media (min-width: 768px) {

.AccountDashboard_subTitle__LZZ0X {
font-size: 28.833px;
line-height: 124%
}
}

@media (min-width: 1024px) {

.AccountDashboard_subTitle__LZZ0X {
font-size: 32.437px;
line-height: 120%
}
}


.QTImage_imageQuestion__lSGCd > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(16px * var(--tw-space-y-reverse));
}.QTImage_imageQuestion__lSGCd {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    border: 2px dashed #CAD8E2;
    border-radius: 12px;
    transition: border-color 0.3s linear;
}

.QTImage_error__NiB8Z {
    border-color: #DB0F64;
}

.QTImage_interactive__xuOUh {
    cursor: pointer;
}

.QTImage_interactive__xuOUh:hover,
    .QTImage_interactive__xuOUh:focus {
        border-color: #007ACC;
        outline: none;
    }

.QTImage_successMessage__Nghm4 {
    max-width: 21.375rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.CheckboxIcon_checkbox__Ty7w0 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 6px;
    background-color: #FFFFFF;
    border: 1px solid #CAD8E2;
}

.CheckboxIcon_checked__Lfuds {
    background-color: #303C69;
    border: #303C69;
}

.CheckboxIcon_disabled__qzdJT {
    border-color: #CAD8E2;
    background-color: #FFFFFF;
}

.CheckboxIcon_disabled__qzdJT.CheckboxIcon_checked__Lfuds {
        background-color: #CAD8E2;
    }

.CheckboxIcon_animated__wuzoY {
    transition-duration: 300ms;
}

.CheckboxIcon_instant__QIcYD {
    transition-duration: 0ms;
}

.OptionBlock_block__jAV3b {
    position: relative;
    display: block;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #CAD8E2;
    background-color: #FFFFFF;
    cursor: pointer;
}

    .OptionBlock_block__jAV3b:focus-visible {
        outline: none;
    }

    .OptionBlock_block__jAV3b:focus .OptionBlock_indicator___Smqu, .OptionBlock_block__jAV3b:hover .OptionBlock_indicator___Smqu {
            border-color: #007ACC;
        }

.OptionBlock_indicator___Smqu {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: 12px;
    pointer-events: none;
}

.OptionBlock_indicatorChecked__e1pLP .OptionBlock_indicator___Smqu {
        border: 2px solid #1599E0;
        box-shadow: 0px 0px 0px 2px rgba(23, 167, 196, 0.10);
    }

.OptionBlock_indicatorInvalid__tTyp_ .OptionBlock_indicator___Smqu {
        border-color: #DB0F64;
        box-shadow: none;
    }

.OptionBlock_indicatorDisabled__s1h5V {
    cursor: not-allowed;
}

.OptionBlock_indicatorDisabled__s1h5V .OptionBlock_indicator___Smqu {
        border-color: #CAD8E2;
        box-shadow: none;
    }

.OptionBlock_indicatorDisabled__s1h5V > * {
        pointer-events: none;
    }

.RadioOption_option__MMfmQ {

    font-family: sofia-pro, arial, sans-serif;

    font-weight: 700;

    font-size: 14.222px;

    line-height: 150%;

    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.RadioOption_optionDisabled__itrZV {
    cursor: not-allowed;
    color: #5A5E6E;
}

.Checkbox_checkbox__BtAgD {

    font-family: fields, arial, system-ui, serif;

    font-weight: 600;

    font-size: 14.222px;

    line-height: 150%;

    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.Checkbox_checkboxDisabled__T3pfH {
    cursor: not-allowed;
    color: #5A5E6E;
}

.TreatmentCardPrice_container__IvxE2 {
    display: flex;
    gap: 8px;
    align-items: baseline;
}

.TreatmentCardPrice_prefix__45Q_y {
    display: block;
    text-transform: capitalize;
}

.TreatmentCardPrice_price__jtI_j {
    display: inline-block;
    font-size: 18px;
    line-height: 100%;
    color: #007ACC;
    vertical-align: bottom;
}

@container (min-width: 310px) {

.TreatmentCardPrice_price__jtI_j {
        font-size: 18px
}
    }

button.PriceFilter_option__rJisN {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: transparent !important;
} /* Important need to override foundry css. */

    button.PriceFilter_option__rJisN:hover {
        background-color: transparent !important;
    }

.FilterShowMore_button__bZJKY span {
        text-decoration: underline;
    }

    .FilterShowMore_button__bZJKY:hover span, .FilterShowMore_button__bZJKY:focus span {
            text-decoration: none;
        }

button.FilterRadioOption_option__6izR_ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: transparent !important;
} /* Important need to override foundry css. */

    button.FilterRadioOption_option__6izR_:hover {
        background-color: transparent !important;
    }

.FilterActions_filterActions__UBi6c {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    justify-content: space-between;
    padding: 16px;
    background-color: #FFFFFF;
    border-top: 1px solid #CAD8E2;
}

.TreatmentFilter_filters___nXz4 {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 24px 16px;
    overflow: auto;
}

    .TreatmentFilter_filters___nXz4 > section {
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid #CAD8E2;
    }

    .TreatmentFilter_filters___nXz4 > section:last-child {
        margin: 0;
        padding: 0;
        border-bottom: none;
    }

.TreatmentFilter_button__VDdSn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    --button-bordered-default-background: #FFFFFF;
}

.TreatmentFilter_button__VDdSn:hover,
    .TreatmentFilter_button__VDdSn:focus {
        --button-bordered-hover-background: #FFFFFF;
    }

.TreatmentFilter_button__VDdSn {

    background-color: #FFFFFF;
}

.TreatmentFilter_filterCount__gOh9l {
    position: absolute;
    top: -0.625rem;
    right: -0.175rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.375rem;
    padding-top: 0.1875rem;
    padding-bottom: 0.3125rem;
    background-color: #007ACC;
    border-radius: 9999px;
    height: 18px;
    width: 18px;
}

.TreatmentSort_button__RpxZe {

    display: flex;

    align-items: center;

    justify-content: space-between;

    min-width: 10rem;

    --button-bordered-default-background: #FFFFFF;
}

    .TreatmentSort_button__RpxZe:hover,
    .TreatmentSort_button__RpxZe:focus {

        --button-bordered-hover-background: #FFFFFF;
    }

    .TreatmentSort_button__RpxZe {

    background-color: #FFFFFF;
}

.SortAndFilterNoResults_clearAll__PxO2a span {
        text-decoration: underline;
    }

    .SortAndFilterNoResults_clearAll__PxO2a:hover span {
            text-decoration: none;
        }

.ConditionPageTreatments_treatments__tR61i {

    grid-template-columns: repeat(2, minmax(0, 1fr));

    display: grid;
    gap: 12px
}

    @media (min-width: 768px) {.ConditionPageTreatments_treatments__tR61i {

        grid-template-columns: repeat(3, minmax(0, 1fr));

        gap: 16px
    }
    }

.Accordion_accordion__79fvQ {

    margin-left: 0;

    margin-bottom: 0;

    list-style-type: none;

    padding: 0;

    text-align: left;
}

    .Accordion_accordion__79fvQ button {

    outline-width: 2px;

    outline-offset: 2px;
}

    .Accordion_accordion__79fvQ button:focus {

    outline-color: #007ACC;
}

.Accordion_accordion__item__K3aSN {

    display: flex;

    flex-direction: column;

    gap: 0;

    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid #CAD8E2;
}

.Accordion_accordion__item__K3aSN:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
    }

.Accordion_accordion__item__K3aSN.Accordion_accordion__item_is_active__bRxGw .Accordion_accordion__icon__oMUml {
            transform: rotateZ(90deg);
        }

.Accordion_accordion__item__K3aSN {

    transition: gap 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.Accordion_accordionItemTitle__t19tU {
    display: grid;
}

.Accordion_accordionItemTitle__t19tU h3 {
        grid-area: 1 / 1;
        max-width: calc(100% - 2.5rem);
    }

.Accordion_accordionItemTitle__t19tU button {
        grid-area: 1 / 1;
    }

.Accordion_accordion__icon__oMUml {
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1), fill 0.4s linear;
}

.Accordion_accordion__content__Fzl8O {
    overflow: hidden;
    transition: max-height 0.8s cubic-bezier(0.23, 1, 0.32, 1), padding 0.8s cubic-bezier(0.23, 1, 0.32, 1),
        margin-top 0.8s cubic-bezier(0.23, 1, 0.32, 1), margin-left 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.Accordion_accordion__content__Fzl8O.Accordion_is_collapsed__GCpM_ {
        max-height: 0;
    }

.Accordion_accordion__content__Fzl8O > div > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(16px * var(--tw-space-y-reverse));
}

.Accordion_accordion__content__Fzl8O > div {

        padding-top: 24px
    }

.Accordion_accordion__disabled__q_tVt {
    border: none;
}

.Accordion_accordion__disabled__q_tVt .Accordion_accordion__content__Fzl8O {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
    }

@media screen and (min-width: 767px) {

.Accordion_accordion__disabled__q_tVt .Accordion_accordion__content__Fzl8O {
            border-top: 0;
            margin-top: 0
    }
        }

.Accordion_accordion__disabled__q_tVt .Accordion_accordion__trigger__cX2o0 {
        pointer-events: none;
        padding: 0;
    }

.AuthorMeta_authorMeta__zIwPs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32px * var(--tw-space-y-reverse));
}

.AuthorMeta_authorMeta__zIwPs {
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity));
    padding-top: 32px;
    padding-bottom: 32px;
}

@media (min-width: 768px) {

    .AuthorMeta_authorMeta__zIwPs {
        padding-top: 48px;
        padding-bottom: 48px;
    }
}

.AuthorMeta_container__vJxRM {
    max-width: 1152px;
    padding: 0 16px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px) {

.AuthorMeta_container__vJxRM {
        padding: 0 24px
}
    }

.AuthorMeta_metadata__WWHMN {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    border-bottom: 1px solid #CAD8E2;
}

.AuthorMeta_metadata__WWHMN:last-of-type {
        border-bottom: none;
    }

.AuthorMeta_authorInfo__BcZAo > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px * var(--tw-space-y-reverse));
}

.AuthorMeta_authorInfo__BcZAo {
    padding-bottom: 32px;
}

@media (min-width: 768px) {

    .AuthorMeta_authorInfo__BcZAo {
        padding-bottom: 0;
    }
}

.AuthorMeta_linksContainer__hUegE {
    grid-column: span 12 / span 12;
    padding-bottom: 16px;
}

@media (min-width: 768px) {

    .AuthorMeta_linksContainer__hUegE {
        grid-column: span 4 / span 4;
    }
}

.AuthorMeta_linksContainer__hUegE {

    border-bottom: 1px solid #CAD8E2;
}

@media (min-width: 768px) {

.AuthorMeta_linksContainer__hUegE {
        padding-bottom: 0;

        border-bottom: none;
    }
    }

.AuthorMeta_linksContainer__hUegE:last-of-type {
        border-bottom: none;
    }

@media (max-width: 767px) {.Reel_track__ajN3Z {
        display: flex;
        margin-left: calc(var(--gutter, 1rem) * -1);
        margin-right: calc(var(--gutter, 1rem) * -1);
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none;
        overflow: auto
}

        .Reel_track__ajN3Z::-webkit-scrollbar {
            display: none;
        }

        .Reel_track__ajN3Z > li {
            scroll-snap-align: start;
            padding-left: var(--gutter, 1rem);
        }

            .Reel_track__ajN3Z > li:last-child {
                padding-right: var(--gutter, 1rem);
            }

.Reel_scrollPadding__rbGEp {
        padding-inline-end: calc(100% - calc(var(--card-width) - calc(var(--gutter, 1rem) * 2)))
}
    }

.Reel_indicators__rZo4y {
    display: flex;
    margin-top: 12px;
    justify-content: center;
    gap: 8px;
}

@media (min-width: 768px) {

.Reel_indicators__rZo4y {
        display: none
}
    }

.Reel_indicators__rZo4y button {
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background: #CAD8E2;
        cursor: pointer;
        transition: 100ms width linear;
    }

@supports (animation-timeline: scroll()) {
        .Reel_indicators__rZo4y button {
            animation: Reel_blink__VCxCV both linear;
            animation-range:
                contain calc(100% - calc(var(--card-width) - var(--gutter, 1rem)))
                contain calc(100% + calc(var(--card-width) - var(--gutter, 1rem)));
            transition: none;
        }

    @keyframes Reel_blink__VCxCV {
        50% {
            width: 64px;
        }
    }
}

@media (min-width: 768px) {.VariantSelector_list__V6dOi ul {

grid-template-columns: repeat(3, minmax(0, 1fr));

            display: grid;
            gap: 12px
}
        }

@media (max-width: 767px) {

.VariantSelector_disabled__fD_gn ul {

grid-template-columns: repeat(2, minmax(0, 1fr));

            display: grid;
            gap: 12px
}

.VariantSelector_disabled__fD_gn .VariantSelector_option__HWf3q {
            width: 100%
    }
        }

.VariantSelector_option__HWf3q {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    width: 10rem;
    height: 100%;
    padding: 16px;
    border-radius: 12px;
    background-color: #FFFFFF;
    border: 1px solid #CAD8E2;
    transition: border-color 300ms cubic-bezier(0.23, 1, 0.32, 1);
    cursor: pointer;
}

@media (min-width: 768px) {

.VariantSelector_option__HWf3q {
        width: 100%
}
    }

.VariantSelector_option__HWf3q img {
        width: 0.75rem;
        height: 0.75rem;
    }

.VariantSelector_option__HWf3q:focus-visible {
        outline: none;
    }

.VariantSelector_option__HWf3q:hover,
    .VariantSelector_option__HWf3q:focus,
    .VariantSelector_option__HWf3q:focus-within {
        border-color: #007ACC;
    }

.VariantSelector_option__HWf3q:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        border: 2px solid #007ACC;
        transition: opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
        box-shadow: 0px 0px 0px 2px rgba(23, 167, 196, 0.10);
        opacity: 0;
        pointer-events: none;
    }

.VariantSelector_option__HWf3q[aria-checked=true] {
        border-color: transparent;
    }

.VariantSelector_option__HWf3q[aria-checked=true]:before {
            opacity: 1;
        }

.RegularQuantitySelector_wrapper__eDy3i {
    position: relative;
    margin-top: 4px;
    border: 1px solid #CAD8E2;
    border-radius: 12px;
    overflow: visible;
}

    .RegularQuantitySelector_wrapper__eDy3i > button {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px;
        text-align: left;
        outline-offset: 4px;
    }

    @media (min-width: 768px) {

    .RegularQuantitySelector_wrapper__eDy3i > button {
            padding-left: 24px;
            border-left: 1px solid transparent
    }
        }

    .RegularQuantitySelector_wrapper__eDy3i > button > span:last-of-type {
            margin-left: auto;
            padding-left: 16px;
            padding-right: 4px;
        }

    .RegularQuantitySelector_wrapper__eDy3i > button svg {
            transform: rotate(90deg);
            fill: #007ACC;
        }

.RegularQuantitySelector_dropDown__nhIGV {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 4px 12px 12px;
    transition-duration: 300ms; /* Not sure if this is needed for Transition component */
    z-index: 1000;
}

.RegularQuantitySelector_dropDown__nhIGV:before {
        content: '';
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 100dvh;
        background-color: #303C69;
        opacity: 0.75;
        transition-property: opacity;
        transition-duration: 300ms;
        transition-timing-function: linear;
    }

@media (min-width: 768px) {

.RegularQuantitySelector_dropDown__nhIGV:before {
            position: absolute;
            top: calc(0px - calc(12px - 1px));
            bottom: auto;
            left: -1px;
            width: calc(100% + 2px);
            max-height: 35.5rem;
            height: calc(100% + 12px);
            border-width: 0 1px 1px;
            border-style: solid;
            border-color: #CAD8E2;
            border-radius: 0 0 12px 12px;
            background-color: #FFFFFF;
            opacity: 1 !important;
            transition-property: max-height;
            pointer-events: none
    }
        }

.RegularQuantitySelector_dropDownTransition__f4EVn:before {
    opacity: 0;
}

@media (min-width: 768px) {

.RegularQuantitySelector_dropDownTransition__f4EVn:before {
        max-height: 0.75rem
}
    }

.RegularQuantitySelector_overlay__ClcBV {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 21.25rem;
    padding: 24px 16px 16px;
    background-color: #FFFFFF;
    border-radius: 24px 24px 0 0;
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: linear;
}

@media (min-width: 768px) {

.RegularQuantitySelector_overlay__ClcBV {
        position: relative;
        height: auto;
        max-height: 17.75rem;
        padding: 0;
        transition-property: opacity;
        transform: none
}
    }

.RegularQuantitySelector_overlay__ClcBV ul > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

.RegularQuantitySelector_overlay__ClcBV ul {

        position: relative;
        flex-grow: 1;
        max-height: 17.75rem;
        overflow-y: auto;
    }

.RegularQuantitySelector_overlay__ClcBV ul:focus-visible,
        .RegularQuantitySelector_overlay__ClcBV ul:focus {
            outline: none;
        }

.RegularQuantitySelector_overlay__ClcBV ul li {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            padding: 8px 16px 8px 12px;
            border: 1px solid #CAD8E2;
            border-radius: 12px;
        }

.RegularQuantitySelector_overlay__ClcBV ul li[aria-disabled=true] {
                cursor: not-allowed;
            }

.RegularQuantitySelector_overlay__ClcBV ul li:not([aria-disabled=true]):before {
                    content: '';
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    width: calc(100% + 2px);
                    height: calc(100% + 2px);
                    border-radius: inherit;
                    border: 2px solid #007ACC;
                    transition: opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
                    opacity: 0;
                    pointer-events: none;
                }

.RegularQuantitySelector_overlay__ClcBV ul li:not([aria-disabled=true]):hover:before,
                .RegularQuantitySelector_overlay__ClcBV ul li:not([aria-disabled=true])[data-headlessui-state=active]:before {
                    opacity: 1;
                }

.TotalsSummary_list__uPyxw > li {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

        .TotalsSummary_list__uPyxw > li + li {
            padding-top: 12px;
            margin-top: 12px;
            border-top: 1px solid #CAD8E2;
        }

/* Position left column, below reasons to buy. */
.NewSelector_grid__OXS8z {

    grid-template-columns: repeat(1, minmax(0, 1fr));

    display: grid;
    gap: 24px;
}
@media (min-width: 768px) {
.NewSelector_grid__OXS8z {
        grid-template-columns: 280px 1fr;
        grid-template-rows: repeat(3, auto);
        -moz-column-gap: 32px;
             column-gap: 32px;
        row-gap: 0

        /* If there is no reasonsToBuy section, we need to centre the header. */
}
        .NewSelector_grid__OXS8z:not(:has(.NewSelector_reasonsToBuy__7_CcO)) .NewSelector_header__9Z_r0 {
            grid-area: 1 / 2 / 3 / 3;
            align-self: center;
        }
    }
@media (min-width: 1024px) {
.NewSelector_grid__OXS8z {
        grid-template-rows: repeat(4, auto)

        /* If there is no reasonsToBuy section, we need to set header back to the top. */
}
        .NewSelector_grid__OXS8z:not(:has(.NewSelector_reasonsToBuy__7_CcO)) .NewSelector_header__9Z_r0 {
            grid-area: 1 / 2 / 1 / 3;
            align-self: start;
        }
    }

/* Position left column, below header. */
.NewSelector_image__Ujhcr {
    padding: 8px;
    border-radius: 12px;
    border: 1px solid #ccf2ff80;
}
/* Position top left */
@media (min-width: 768px) {
.NewSelector_image__Ujhcr {
        grid-area: 1 / 1 / 3 / 2;
        padding: 0;
        border: none
}
    }
.NewSelector_image__Ujhcr img {
        max-width: 10rem;
        width: 100%;
        margin: 0 auto;
    }
@media (min-width: 768px) {
.NewSelector_image__Ujhcr img {
            max-width: 17.5rem
    }
        }

/* Position top right */

@media (min-width: 768px) {

.NewSelector_header__9Z_r0 {
        grid-area: 1 / 2 / 1 / 3;
        align-self: end
}
    }

/* Position top right */

@media (min-width: 1024px) {

.NewSelector_header__9Z_r0 {
        grid-area: 1 / 2 / 1 / 3;
        align-self: start
}
    }

/* Position top right, under header */

@media (min-width: 768px) {

.NewSelector_reasonsToBuy__7_CcO {
        grid-area: 2 / 2 / 2 / 3;
        padding-top: 16px
}
    }

/* Position bottom left, under image */

@media (min-width: 1024px) {

.NewSelector_reasonsToBuy__7_CcO {
        grid-area: 3 / 1 / 4 / 2;
        padding-top: 24px
}
    }

/* Position bottom, across all columns */

@media (min-width: 768px) {

.NewSelector_treatmentSelector__LY5vp {
        grid-area: 3 / 1 / 3 / 3;
        padding-top: 32px
}
    }

/* Position bottom right, under header */

@media (min-width: 1024px) {

.NewSelector_treatmentSelector__LY5vp {
        grid-area: 2 / 2 / 5 / 3;
        padding-top: 24px
}
    }

.PriceBreakListing_list__rJk_5 {
    margin-top: 32px;
}

    .PriceBreakListing_list__rJk_5 li + li {
        margin-top: 12px;
        padding-top: 12px;
        border-top: 1px solid #CAD8E2;
    }

.ExitCard_card__jKNlE {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    transition: box-shadow 350ms ease-out;
}

    .ExitCard_card__jKNlE:hover {
        box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;
    }

.ExitCard_button__JyQbc {
    position: relative;
    margin-top: 20px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
}

.ExitCard_cutoutBackground__IMACp {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    transform: translate(0, 50%);
}

.ExitCard_buttonLink__A5j7r {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
}

.ConsultationAssessmentCard_assessment__dT_S_ {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

@media (min-width: 768px) {

    .ConsultationAssessmentCard_assessment__dT_S_ {
        flex-direction: row;
        gap: 32px;
    }

    .ConsultationAssessmentCard_sectionTitle__BMler {
        font-size: 36.491px;
        font-weight: 500;
    }
}

@media (min-width: 1024px) {

    .ConsultationAssessmentCard_sectionTitle__BMler {
        font-size: 46.184px;
        font-weight: 400;
        line-height: 120%;
    }
}

.ConsultationAssessmentCard_sectionTitle__BMler span {
    display: block;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(49 204 156 / var(--tw-text-opacity));
}

.ConsultationAssessmentCard_card__gJe0X {
    padding: 24px;
}

@media (min-width: 768px) {

.ConsultationAssessmentCard_card__gJe0X {
        max-width: 23rem;
        margin-top: 32px;
        margin-left: auto;
}
    }

.ConsultationAssessmentCard_card__gJe0X {

    width: 100%;
    max-width: 24.37rem;
}

@media (min-width: 768px) {

    .ConsultationAssessmentCard_cardHeader__tws0l {
        font-size: 28.833px;
        line-height: 124%;
    }
}

@media (min-width: 1024px) {

    .ConsultationAssessmentCard_cardHeader__tws0l {
        font-size: 32.437px;
        line-height: 120%;
    }
}

.ConsultationAssessmentCard_cardList__mtd2l {
    margin-top: 32px;
    flex-basis: 50%;
    list-style-type: none;
    flex-direction: column;
    gap: 16px;
    display: none;
}

@container (min-width: 28rem) {

    .ConsultationAssessmentCard_cardList__mtd2l {
        max-width: 720px;
        justify-content: center;
        gap: 16px;
    }
}

@container (min-width: 32rem) {

    .ConsultationAssessmentCard_cardList__mtd2l {
        gap: 24px;
    }
}

@media (min-width: 768px) {

    .ConsultationAssessmentCard_cardList__mtd2l {
        display: flex;
    }
}

.ConsultationAssessmentCard_cardList__mtd2l:nth-child(3) {
    margin-top: 24px;
    display: flex;
    --tw-text-opacity: 1;
    color: rgb(204 242 255 / var(--tw-text-opacity));
}

@media (min-width: 768px) {

    .ConsultationAssessmentCard_cardList__mtd2l:nth-child(3) {
        display: none;
    }
}

