.list {
    & > li {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & + li {
            padding-top: theme('spacing.075');
            margin-top: theme('spacing.075');
            border-top: 1px solid theme('colors.decorators.subtle');
        }
    }
}
