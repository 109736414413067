.accordion {
    @apply text-left p-0 ml-0 mb-0 list-none;

    button {
        @apply outline-offset-2 focus:outline-actions-secondary outline-2;
    }
}

.accordion__item {
    @apply flex flex-col gap-0;

    padding-top: theme('rhythm.xsmall');
    margin-top: theme('rhythm.xsmall');
    border-top: theme('accordion.border');

    &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
    }

    &.accordion__item_is_active {
        .accordion__icon {
            transform: rotateZ(90deg);
        }
    }

    transition: gap 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.accordionItemTitle {
    display: grid;

    & h3 {
        grid-area: 1 / 1;
        max-width: calc(100% - 2.5rem);
    }

    & button {
        grid-area: 1 / 1;
    }
}

.accordion__icon {
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1), fill 0.4s linear;
}

.accordion__content {
    overflow: hidden;
    transition: max-height 0.8s cubic-bezier(0.23, 1, 0.32, 1), padding 0.8s cubic-bezier(0.23, 1, 0.32, 1),
        margin-top 0.8s cubic-bezier(0.23, 1, 0.32, 1), margin-left 0.8s cubic-bezier(0.23, 1, 0.32, 1);

    &.is_collapsed {
        max-height: 0;
    }

    & > div {
        @apply space-y-100;

        padding-top: theme('rhythm.xsmall')
    }
}

.accordion__disabled {
    border: none;

    .accordion__content {
        padding-left: 0;
        padding-right: 0;
        margin: 0;

        @media screen and (min-width: 767px) {
            border-top: 0;
            margin-top: 0;
        }
    }

    .accordion__trigger {
        pointer-events: none;
        padding: 0;
    }
}
